.card  {

    position: relative;
    height: 100%;
  
  .card-info {
    padding: 1rem;
    //display: grid;
    //grid-template-rows: 1fr 2.5rem;
    //grid-template-columns: 3fr 1fr;
  }
  
  .card-title {
    //grid-row: 1 / 2;
    //grid-column: 1 / 2;
    width: 100%;
  }
  
  .card-icon {
    position: absolute;
    right: 1rem;
  }
  
  .card-author {
    //grid-row: 2 / 3;
    //grid-column: 1 / 2;
    //align-self: bottom;
    margin-bottom: 0;
  }
  
  .card-stats {
    align-self: bottom;
    justify-self: right;
   
  }
  
}