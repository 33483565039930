.header {

    .header-hero {
        overflow: hidden;
        height: 60rem;
        position: relative;

        @include breakpoint(medium) {
            height: 50vh;
        }

        .white-grad {
            top: 0;
            position: absolute;
        }

        img {
            object-position: 50% 30%;
        }
    }

    .logo {
        margin-bottom: -20px;
    }

    .header-content-container {
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    .top-bar-container {
        z-index: 3;
        height: 10vh;
    }

}

.big-round-container {

    position: relative;
    height: 3rem;
    overflow: hidden;

    .big-round {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 13rem;
        bottom: 0;
    }

}