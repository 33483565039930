.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.clean-list {
    list-style-type: none;
    margin-left: 0;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
    left: 0;
    right: 0;
}

.object-fit {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.responsive-info {
    position: fixed;
    bottom: 0;
    z-index: 40;
    visibility: hidden;
}

.skip-navigation A {
    position: fixed;
    top: -40px;
    z-index: 50;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    left: 10px
}

.skip-navigation A:focus {
    top: 0;
    opacity: 1
}

.no-margin {
    margin: 0;
}

.no-break {
    white-space: nowrap;
}

.rtl {
    direction: rtl;
}