 
  /* ==========================================================================
       Base Typography
       ========================================================================== */
  body {
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  /* h1, h2, h3, h4, h5, h6 { font-family: 'robotoBold', sans-serif; font-weight: 700; font-style: normal; } */
  .bold-black {
    font-family: "PT Sans", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  strong {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  .bold {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  table tr td,
  table tr th {
    font-size: 100%;
  }
  
  
  /*Text Helpers*/
  .little-header {
    font-size: 1.4rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1;
  }
  
  .large-text {
    font-size: 1.7rem;
  }
  
  .small-text {
    font-size: 0.7rem;
  }
  
  .little-text {
    text-rendering: optimizeLegibility;
    font-size: 0.8rem;
    line-height: 1.2;
  }
  
  .little-text.info {
    text-rendering: optimizeLegibility;
    font-size: 0.8rem;
    line-height: 1.2;
    margin-bottom: 0;
    padding-top: 5px;
  }
  
  .all-caps {
    text-transform: uppercase;
  }
